













import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator'
import { BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
@Component({
  components: { BInputGroup, BFormInput, BInputGroupAppend }
})
export default class Field extends Vue {
  @Prop({ type: String, default: null })
  placeholder!: string | null;

  @ModelSync('value', 'change', { type: [String, Number] })
  readonly stateValue!: string | number
}
